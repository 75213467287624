/* FOUS prevention */
xc-header:not([state]) {
  display: block;
  min-height: 80px;
  background: #FFF;
  border-bottom: 1px solid #ceceda;
}

xc-footer {
  display: block;
  min-height: 53px;
  background: #44484C;
}

/* Default polaris styles wrap tooltips to 1px */
.xc-tooltip {
  white-space: nowrap;
}

/* Default .page-app styles add 35px to bottom */
.page-app.page-app--polaris-global {
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
  &>main {
    flex: 1 0 auto;
  }
}

.xc-header--back-button {
  cursor: pointer;
}