.page-app--harnessed .page-header {
  justify-content: stretch;
  align-items: stretch;
  line-height: 48px;
  padding: 0;
  height: 48px;
  &>* {
    flex: 0 1 auto;
    &>a, &>button {
      line-height: 48px;
      height: 48px;
      display: inline-block;
    }
  }
  .page-header__title {
    flex: 10 0 auto;
    position: static;
    order: 2;
    text-align: center;
    left: 0;
  }
  .page-header__cancel {
    order: 10;
    padding-right: 12px;
  }
  .page-header__back {
    order: 0;
    &>*:last-of-type {
      padding-left: 12px;
    }
  }
  .sign-out.button {
    order: 1;
    line-height: 48px;
    padding-left: 12px;
    float: none;
    position: static;
    opacity: 0;
    pointer-events: none;
  }  
}
.sign-out.button {
  color: $white;
  float: right;
  top: -33px;
  right: 10px;
}
