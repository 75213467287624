.button--text.is-loading {
  background-color: transparent !important;
  border-color: transparent !important;
  color: $blue-sky !important;

  &::before {
    content: "";
    display: inline-block;
    width: 14px;
    height: 14px;
    margin: 0 3px;
    vertical-align: middle;
    margin-bottom: 2px;
    border-radius: 50%;
    border: 2px solid #ccc;
    border-left-color: #0272b6;
    animation: spin 1s linear infinite;
  }
}
