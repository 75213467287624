
.key-value-group--mobile-table {
  display: table;
  &>.key-value-group__item {
    display: table-row-group;
    &>.key-value {
      display: table-row;
      &>.key-value__key, &>.key-value__value {
        display: table-cell;
      }
      &>.key-value__key {
        padding-right: 1rem;
      }
    }
  }
  @include respond-to(768) {
    display: block;
    &>.key-value-group__item {
      display: block;
      &>.key-value {
        display: block;
        &>.key-value__key, &>.key-value__value {
          display: block;
        }
        &>.key-value__key {
          padding-right: 0;
        }
      }
    }
  }
}
