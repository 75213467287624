.form-control-group {}

.form-control-group--inline {
  .form-control-group__item {
    display: inline-block;
    margin-right: 48px;
  }
}

// shame
.form-control-group--flex {
  width: calc(100% + 12px);
  margin-left: -6px;
  display: flex;
  margin-bottom: 24px;

  > .form-control-group__item {
    padding: 0 6px;
    width: 50%;

    .form-control,
    .form-control-group {
      margin-bottom: 0;
    }
  }
}

// shame
.form-control-group--flex-at-768 {
  @include respond-to(768) {
    width: calc(100% + 12px);
    margin-left: -6px;
    display: flex;
    margin-bottom: 24px;
  }

  > .form-control-group__item {
    @include respond-to(768) {
      padding: 0 6px;
      width: 50%;
    }

    .form-control,
    .form-control-group {
      @include respond-to(768) {
        margin-bottom: 0;
      }
    }
  }
}

// shame
.form-control-group--flex-at-1024 {
  @include respond-to(1024) {
    width: calc(100% + 12px);
    margin-left: -6px;
    display: flex;
    margin-bottom: 24px;
  }

  > .form-control-group__item {
    @include respond-to(1024) {
      padding: 0 6px;
      width: 50%;
    }

    .form-control,
    .form-control-group {
      @include respond-to(1024) {
        margin-bottom: 0;
      }
    }
  }
}

.form-control-group {
  > .form-control-group__item--1of3-at-1024 {
    @include respond-to(1024) {
      width: 33.3%;
    }
  }

  > .form-control-group__item--2of3-at-1024 {
    @include respond-to(1024) {
      width: 66.6%;
    }
  }
}

.form-control-group--boolean-flex-at-768 {
  @include respond-to(768) {
    display: flex;
    margin-bottom: 0;
  }

  > .label,
  > .form-control {
    @include respond-to(768) {
      margin-bottom: 0;
      margin-right: 54px;
    }
  }
}

input.dollar-amount {
  text-align: right;
}

// shame
.form-control.form-control--boolean {
  .label,
  label,
  .form-control__label {
    display: flex;
    padding-left: 0;
    &:before {
      position: static;
      margin-right: 18px;
      flex-shrink: 0;
    }
    @include respond-to(768) {
      align-items: center;
    }
  }
}

//shame
.form-control label .button,
.form-control__label .button {
  position: relative;
  top: -0.125em;
}
