.page-app {
  padding-bottom: 65px; /* 17 text + 24 gutters */
  position: relative;
  min-height: 100vh;
}

// don't show outline when focusing on all page content
// during client-side navigation
h1:focus,
.page-main:focus {
  outline: none;
}

.page-footer {
  padding-bottom: 24px;
  position: absolute;
  text-align: center;
  bottom: 0;
  right: 0;
  left: 0;
}

.payment-section {
  @include clearfix; //prevent collasping margins
  margin-bottom: 12px;

  > .card,
  > .card-group {
    margin-bottom: 12px;
  }
}

.color--green-forest {
  color: $green-forest;

  @include ui-grey {
    color: $green-forest;
  }
}

.payment-instrument-helper-text {
  margin-bottom: 6px;
  display: block;

  @include respond-to(768) {
    display: inline-flex;
    vertical-align: baseline;
    margin-bottom: 0;
    margin-right: 12px;
  }
}

.form-control.form-control--boolean .form-control__label[for="autopay"] {
  align-items: center;
  .autopay-label {
    display: inline-block;
    flex-wrap: wrap;
    align-items: center;
    @include respond-to(768) {
      display: inline-flex;
    }
  }
}

.form-control--inline-at-768 > .form-control__label {
  @include respond-to(768) {
    padding-top: 14px;
  }
}

.form-control--cvv {
  max-width: 180px;
  margin-bottom: 0;
}

@media print {
  a[href]:after {
    content: '';
  }
  .do-not-print {
    display: none;
  }
}

.hgroup {
  margin-bottom: 12px;
}

// keep these at the bottom
.mb0  { margin-bottom: 0; }
.mb6  { margin-bottom: 6px !important; } // sorry need to overcome hgroup
.mb12 { margin-bottom: 12px !important; } // sorry need to overcome hgroup
.mb18 { margin-bottom: 18px; }
.mb24 { margin-bottom: 24px; }
.mb30 { margin-bottom: 30px; }

.mr6  { margin-right: 6px; }
.ml12 { margin-left: 12px; }
.ml40 { margin-left: 40px; }

.mr12 { margin-right: 12px; }

.pt0 { padding-top: 0 !important; }
.pt6 { padding-top: 6px; }
.move-up12 { margin-top: -12px; }

.d-flex { display: flex; }
.d-inline-block { display: inline-block }

.align-items-center { align-items: center; }
.align-items-start  { align-items: flex-start; }

.text-left    { text-align: left; }
.text-right   { text-align: right; }
.text-center  { text-align: center; }

.circle--alert,
.circle--minty {
  color: $white;
}

.key-value-group__item {
  margin-bottom: 12px;
}

.hide-focus {
  outline: none;
}

xc-footer[theme="light"] {
  background-color: white;
}
