input.customAmount {
  text-align: right;
  position: relative;
  display: inline-block;
}

@media all and (min-width: 768px) {
  input.customAmount__condensed {
    max-width: 200px;
  }
}
