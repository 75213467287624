.card-type {
  svg {
    position: absolute;
    top: 14px;
    right: 14px;
    height: 24px;
  }
}

.payment-date .hide-for-mobile {
  display: none;
  &+.card__action {
    padding-top: 0;
  }
  @include respond-to(768) {
    display: block;
    &+.card__action {
      padding-top: 12px;
    }
  }
}