.svg-icon {
  display: inline-block;
  width: 60px;
  height: 60px;

  svg {
    max-width: 100%;
    max-height: 100%;
  }
}

.svg-icon--12 {
  width: 12px;
  height: 12px;
}

.svg-icon--16 {
  width: 16px;
  height: 16px;
}

.svg-icon--22 {
  width: 22px;
  height: 22px;
}

.svg-icon--28 {
  width: 28px;
  height: 28px;
}

.svg-icon--36 {
  width: 36px;
  height: 36px;
}

.svg-icon--34 {
  width: 34px;
  height: 34px;
}

.svg-icon--42 {
  width: 42px;
  height: 42px;
}

.svg-icon--40 {
  width: 40px;
  height: 40px;
}

.svg-icon--50 {
  width: 50px;
  height: 50px;
}

.icon-bank-account-routing {
  display: block;
  margin: 0 auto;
  max-width: 260px;
  max-height: 142px;
}

.icon-cvv {
  display: block;
  margin: 0 auto;
  max-width: 260px;
  max-height: 127px;
}

.svg-icon--insensitive {
  pointer-events: none;
}
