.DayPicker {
  word-break: normal;
}

.DayPickerInput {
  display: block;
}

.DayPicker-Day {
  padding: 0.33em 0.5em;
  position: relative;
}

.DayPicker-Day--today {
  color: inherit;
}

%marker {
  content: ' ';
  position: absolute;
  z-index: -1;
  border-radius: 12px;
  width: 24px;
  height: 24px;
  top: 50%;
  left: 50%;
  margin: -11px 0 0 -12px;
  pointer-events: none;
}

.DayPicker-Day--billDueDate:not(.DayPicker-Day--outside):before {
  @extend %marker;
  border: 2px solid $minty;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  background-color: transparent;
  color: $white;
  &:before {
    @extend %marker;
    background: $blue-sky;
  }
  &:hover {
    background-color: transparent;
    color: $white;
  }
}
.DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
  background-color: transparent;
  color: inherit;
  &:before {
    @extend %marker;
    background-color: $cool-grey6;
  }
}

.DayPicker__Key {
  margin: 0 1em 1em 1em;
  text-align: left;
}

.DayPicker__selected, .DayPicker__due-date {
  padding-bottom: 6px;
}

.DayPicker__selected:before, .DayPicker__due-date:before {
  content: ' ';
  display: inline-block;
  vertical-align: middle;
  border-radius: 12px;
  width: 24px;
  height: 24px;
  margin-right: 6px;
  box-sizing: border-box;
}

.DayPicker__selected:before {
  background: $blue-sky;
}

.DayPicker__due-date:before {
  border: 2px solid $minty;
}

.date-picker {
  position: relative;
}

.date-picker__icon {
  position: absolute;
  right: 16px;
  top: 12px;
}

// shame: handles weird flow popcorning in Safari when hover states change.
.DayPicker-Caption {
  height: 1.5em;
  >div {
    position: absolute;
  }
}
