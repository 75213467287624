.payment-plan-option-label {
  @include respond-to(768) {
    text-align: center;
  }

  span {
    display: block;
  }
}

// There is a UX requirement that the radio button appear unselected when the payment plan options
// are disabled. The functionality doesn't exist to unselect a radio button once it is selected,
// so this will just style it as if it is not selected.
.form-control--boolean input[type="radio"].unchecked:checked ~ .form-control__label::before {
  border: 1px solid #646a70;
}

.is-disabled {
  opacity: 0.4;
  pointer-events: none;
}
