.pushdown {
  @include boxShadow;
  background: $white;
  position: relative;
  z-index: 1;
}

.pushdown__wrapper {
  @include wrapper;
  padding-top: 18px;
  padding-bottom: 18px;
  display: flex;
  align-items: center;

  @include respond-to(768) {
    padding-top: 24px;
    padding-bottom: 24px;
  }
}

.pushdown__icon {
  margin-right: 18px;
}

.pushdown__content {
  flex: 1 1 auto;
  .hgroup {
    margin-bottom: 0;
  }

  .body2 {
    margin-bottom: 0;
  }
}
