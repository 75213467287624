.jump--container {
  min-height: 310px;
  transition: height 0.4s;
}
.jump--container>jump-web-component {
  transition: opacity 0.4s;
}
.jump--hidden {
  display: none;
}
.jump--loading {
  background: white;
  position: relative;
}
.jump--loading .loading {
  position: static;
}
.jump--loading .loading__content {
  left: 50%;
  top: 50%;
}
.jump--loading.card--loading-dots {
  height: auto;
}
.jump--loading>jump-web-component {
  opacity: 0;
}

