// shame
.payment-section >  .card-group.card-group--grid-at-768 {
  @include respond-to(768) {
    margin-bottom: 0;
  }
}

.card-group--grid-at-768 {
  @include respond-to(768) {
    display: flex;
    box-shadow: none;
    width: calc(100% + 12px);
    margin-left: -6px;
    flex-wrap: wrap;
  }

  .card-group__item {
    @include respond-to(768) {
      border: 0;
      padding: 0 6px 12px;
      flex: 1 1 50%;
    }
  }

  .card {
    @include respond-to(768) {
      @include boxShadow;
      border-radius: 3px;
      text-align: center;
    }
  }

  .form-control.form-control--boolean .label:before,
  .form-control.form-control--boolean label:before,
  .form-control.form-control--boolean .form-control__label:before {
    @include respond-to(768) {
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .form-control__label-text {
    @include respond-to(768) {
      flex: 1;
    }
  }
}

.card-group--grid-1-at-768 {
  .card-group__item {
    @include respond-to(768) {
      flex: 1 1 auto;
    }
  }
}

.card-group--grid-2-at-768 {
  .card-group__item {
    @include respond-to(768) {
      flex: 1 1 auto;
    }
  }
}

.card-group--grid-4-at-768 {
  .card-group__item {
    @include respond-to(768) {
      flex: 1 1 auto;
    }
  }
}
