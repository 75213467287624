.hgroup--inline {
  @include respond-to(768) {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
}



.hgroup--anchor {
  display: inline-block;
  margin-top: 6px; 

   @include respond-to(768) {
    margin-top: 0px;
  }
}