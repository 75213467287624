.card--circle-plus {
  display: flex;
  padding: 12px;
}

.card--alert {
  border-left: 5px solid $red-watermelon;
}

.card--minty {
  border-left: 5px solid $minty;
}

.card--flex-at-768 {
  text-align: center;

  @include respond-to(768) {
    display: flex;
    text-align: left;
  }

  .card__image {
    flex: 2;
    margin-bottom: 12px;
    text-align: center;

    @include respond-to(768) {
      margin-bottom: 0;
    }

    @include respond-to(1000) {
      flex: 3;
    }
  }

  .card__content {
    flex: 6;

    @include respond-to(768) {
      padding-left: 24px;
      margin-bottom: 0;
    }
  }

  .card__content + .card__action {
    padding-top: 12px;

    @include respond-to(768) {
      padding-top: 0;
    }
  }

  .card__action {
    flex: 4;

    @include respond-to(768) {
      text-align: right;
    }

    @include respond-to(1000) {
      flex: 3;
    }
  }
}

.card__content_12pxleft {
  margin-bottom: 0;
  flex-grow: 1;

  > :last-child {
    margin-bottom: 0;
  }

  + .card__content {
    margin-top: 24px;
    margin-left:12px;
    margin-right:0;
    padding-top: 24px;
    border-top: 1px solid $card-border-color;
  }
}

.card--flex-promo {
  text-align: center;
  position: relative;

  @include respond-to(680) {
    text-align: left;
    padding-left: 260px;
  }

  .card__content {
    position: relative;
    z-index: 1;
  }

  .card__image {
    margin-bottom: 12px;

    @include respond-to(680) {
      margin-bottom: 0;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: 280px;
    }
  }
}

.card--peacock-promo {
  @include respond-to(768) {
    padding-top: 25px;
    padding-bottom: 0;
    padding-left: 0;

    .card__image {
      align-self: flex-end;
      //padding-top: 15px;
      padding-left: 10px;
      flex: 3;

      img {
        max-width: 250px;
        max-height: 250px;
        width: 100%;
      }
    }

    .card__content {
      padding-bottom: 15px;
    }
  }
}
.card-group--cms-promo {
  @include respond-to(768) {
    padding: 12.5px 25px;
  }
}

.card--cms-promo {
  .body4 {
    font-size: 10px;
  }

  @include respond-to(768) {
    display: flex;
    justify-content: center;
    padding: 0;
    border: none;
    align-items: initial;

    .card__image {
      padding: 0 12px 0 0;
      flex: 3;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .card__content {
      padding: 18px 24px 18px 0;
      .hgroup {
        margin-bottom: 0;
        .body1 {
          font-weight: 500;
        }
      }
      .body4 {
        margin-top: 24px;
      }
    }
    .card__content__action {
      margin-top: 24px;
      .button {
        margin: 0;
      }
    }
    .card__content__action a:first-child {
      margin-right: 24px;
    }
    .card__action {
      padding: 24px 0 !important;
    }
  }
}

.card--mini-message {
  display: flex;
  text-align: left;

  .card__icon {
    margin-right: 6px;

    @include respond-to(768) {
      position: relative;
    }

    .svg-icon {
      @include respond-to(768) {
        // use position: absolute so the card height
        // is based on the text not the icon
        @include vertical-align;
      }
    }
  }

  .card__content-inner {
    flex: 1;
  }

  .card__content {
    @include respond-to(768) {
      display: flex;
      padding-left: 40px; // 34 icon + 6 margin
    }
  }
}
.card-group--grid-at-768.card-group--grid-3-at-768 .card-group__item {
  flex: 1 1 33.3%;
}

.card-group--grid-at-768.card-group--grid-at-768-nowrap {
  flex-wrap: nowrap;
}