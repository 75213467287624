.form-toggle--flex .form-toggle__input {
  display: flex;

  @include respond-to(768) {
    align-items: center;
  }
}

.form-toggle__content {
  margin-left: 12px;
}
