body {
  background-color: map-get($ui-grey-map, background-color);
  color: map-get($ui-grey-map, text-color);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
/* Hide everything while polaris is loading / refreshing the page */
body.maw-bill-pay--ready {
  opacity: 1;
}

#root {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
}
.page-main {
  flex: 1 0 auto;
}
