.payment-instrument {
  display: inline-flex;

  @include respond-to(768) {
    align-items: center;
  }
}

.payment-instrument__icon {
  width: 40px;
  height: 26px;
  margin-right: 12px;

  svg {
    vertical-align: top;
  }
}

.payment-instrument__text {
  display: block;

  @include respond-to(768) {
    display: inline;
  }
}

.payment-instrument__badge {
  margin-bottom: 0;
  margin-top: 6px;

  @include respond-to(768) {
    margin-top: 0;
    margin-left: 6px;
  }
}

.payment-instrument__content {
  @include respond-to(768) {
    align-items: center;
    flex-direction: row;
  }
}
