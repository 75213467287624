.action--right-at-768 {
  @extend .action--right;

  text-align: center;

  @include respond-to(768) {
    text-align: right;
  }
}

.action--responsive{
  .action__item {
    @include respond-to(768) {
      display: inline-block;
      vertical-align: top;
      margin: 0 6px;
    }
  }
}
